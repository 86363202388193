import { CalendarProvider } from '../lib/calendar';

const MyApp = ({ Component, pageProps }: any) => {
  return (
    <CalendarProvider>
      <Component {...pageProps} />
    </CalendarProvider>
  );
};

export default MyApp;
